body {
  margin: 0;
  font-family: "Parkinsans";
  font-weight: normal;
  background-color: #ffffff; /* Light background */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height of the viewport */
  position: relative; /* For positioning the floating text */
}

.floating-text {
  position: absolute;
  top: 20px; /* Positioning the text at the top */
  font-size: 24px; /* Larger font size */
  color: rgb(103, 2, 110); /* Dark text color */
  /*background-color: rgb(103, 2, 110);*/
  text-shadow: 1px 1px 3px rgba(160, 0, 152, 0.2); /* Subtle text shadow */
  letter-spacing: -1.3px; /* Slightly increased letter spacing */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from link */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: transform 1s ease; /* Smooth transition for scaling */
}

.floating-text:hover {
  transform: scale(1.2); /* Scale up the text on hover */
  text-decoration: underline; /* Optional: Underline on hover */
}

.image {
  width: 300px; /* Default image size */
  transition: transform 2s ease, box-shadow 0.8s ease; /* Smooth transition */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8); /* Initial shadow */
}

.image.hovered {
  transform: scale(2.5); /* Scale up the image */
  box-shadow: 0 8px 100px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}
